button.resultsViewTypeBtn {
display:none;
}


div.filterDropdown button span.orange {
display: none;
}


#adt-container {
    display: inline;
}
#adt-container::before {
    content: '\A Antitrust/Cartel    ';
    white-space: pre;   
}

#lb-container {
    display: inline;
}

#lb-container::before {
    content: '\A                               ';
    white-space: pre;   
}


#cnu-container {
    display: inline;
}
#cnu-container::before {
    content: "\A Merger                 ";
    white-space: pre;
    
}

#sap-container {
    display: inline;
}
#sap-container::before {
    content: "\A State aid               ";
    white-space: pre;
    
}


#adt-container > button {
	background: #E0E0F4;
}

#AvsC-container > button {
	background: #E0E0F4;
}

#cnu-container > button {
	background: #D1EEF0;
}

#sprc-container > button {
	background: #D1EEF0;
}

#sap-container > button {
	background: #E0EFC7
}

#reg-container > button {
	background: #E0EFC7
}

#probj-container > button {
	background: #E0EFC7
}

#gberobj-container > button {
	background: #E0EFC7
}

#sadtp-container > button {
	background: #E0EFC7
}

#lb-container > button {
	background: #E0EFC7
}

#slb-container > button {
	background: #E0EFC7
}

#ai-container > button {
	background: #E0EFC7
}

#sactp-container > button {
	background: #E0EFC7
}

#dgr-container > button {
	background: #E0EFC7
}