@import "_config.scss";

svg.timeline {
  font: $fontSizeXS sans-serif;
}

svg.timeline path {
  fill: none;
  stroke: $linkStroke;
  stroke-opacity: 0.8;
  stroke-width: 3px;
}

.node circle {
  stroke: $nodeStroke;
  stroke-width: 1px;
}

div.tooltip {
  position: absolute;
  padding: 10px;
  background: $resultHoverBackground;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  font-size: $fontSizeXS;
  width: 400px;
  opacity: 1;
}

div.cardtooltip {
  position: absolute;
  padding: 10px;
  background: $resultHoverBackground;
  border: $cellBorder;
  border-radius: 8px;
  pointer-events: none;
  font-size: $fontSizeXS;
  width: 400px;

  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  z-index: 35001;

}

div.cardtooltip.empty {
  opacity: 0;
}

div.cardtooltip div.card-col-left {
  display: inline-block;
   vertical-align: top;
  width: 30%;
}

div.cardtooltip div.card-col-right {
  display: inline-block;
   vertical-align: top;
  width: 70%;
}


div.BrowserApp {
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: 0;
    top: 52px;
    left: 0;
    right: 0;
    background: $mainBackground;
}

div.Browser {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
}

div.resultsBrowser {
    flex: 0 1 41%;
    background: #fff;
    display: flex;
    flex-flow: column;
}


div.timelineContainer {
    flex: 0 0 auto;
    overflow: show;
    display: flex;
    flex-flow: column;
}

div.docCard {
    cursor: pointer;
    padding: 10px;
}



div.resultsBrowser div.hint {
  padding-top: 10px;  
  padding-bottom: 10px;  
}

div.resultsBrowser div.hint div.contents span.infoIcon {
    background: #ffffffaa;
    color: #498AFF;
    display: inline-block;
    width: 17px;
    text-align: center;
    margin-right: 5px;
    border-radius: 9px;
}

div.resultsBrowser div.hint div.contents {
    padding: 15px ;
    border-radius: 8px;
    background: #498AFF;
    color: #fff;
}

div.resultsBrowser div.hint button.unfilled {
    margin-left: 0;
    border-color: #fff;
    background: none;
    margin-bottom: 0;
}

div.resultsBrowser div.hint button {
    padding: 3px 8px;
}

div.resultsBrowser div.hint button.filled {
    margin-left: 0;
    border-color: #fff;
    background: #fff;
    color: #498AFF;
    margin-bottom: 0;
}


div.resultsBrowser div.hint button.filled:hover,
div.resultsBrowser div.hint button.unfilled:hover {
    opacity: 0.8;
}


div.hint h4 {
    color: #fff;
}


div.cardsContainer {
    flex: 1 1 auto;
    
}

svg.timeline g.thumb > circle + g {
    display: none;
}

svg.timeline g.thumb > circle:hover + g {
    display: block;
    overflow: hidden;
}

svg.timeline g.tick line {
    stroke-width: 1px;
    stroke: #cececece;
    stroke-dasharray: 4 1 2 3;
}



g.readIcon path {
    stroke-width: 1;
}

g.readIcon path#Combined-Shape-Copy-3 {
    fill: #8C96A7;
}


g.flags g.flag path {
    stroke-width: 0;
}

g.flags g.flag-green path {
    fill: #37833C;
}

g.flags g.flag-red path {
    fill: #C8382D;
}

g.flags g.flag-yellow path {
    fill: #FFCB34;
}




div.timelineContainer svg.timeline {
  max-height: calc(100vh - 182px);
  max-width: 41vw;
}


div.timelineContainer svg.timeline g,
div.timelineContainer svg.timeline path {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

div.timelineContainer svg.timeline g circle,
div.timelineContainer svg.timeline g text {
  cursor: pointer;
}


button.saveDocsBtn:hover,
button.listViewBtn:hover,
div.generalHelper:hover {
    background: $interfaceButtonBackgroundHover;
}


button.pnButton,
button.resultsViewTypeBtn {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
}

button.pnButton:first-child,
button.resultsViewTypeBtn:first-child {
    border-radius: $stdBorderRadius 0 0 $stdBorderRadius;
}

button.pnButton:last-child,
button.resultsViewTypeBtn:last-child {
    border-radius: 0 $stdBorderRadius $stdBorderRadius 0;
}


div.closenessRelevance {
    margin-bottom: -50px;
}

#date-slider-container label {
    padding-left: 10px;
}
#cdate-slider-container label {
    padding-left: 10px;
}

label.groupVersionsBtn {
    background: #fff;
    padding: 4px 5px 4px 25px;
    border-radius: $stdBorderRadius;
    color: $transparentBtnColor;
    background: $transparentBtnAlternativeBG;
    border: $transparentBtnAlternativeBorder;
    font-size: $fontSizeM;
    
}

label.groupVersionsBtn span.checkmark {
    top: 4px;
    left: 5px;
}

rect.selectedRangeRectangle {
    fill: #dcdcdc55;
}

div.BrowserApp div.subheader div.optionsMenu div.sortByMenu button {
    padding: 4px 10px;
}


div.BrowserApp div.subheader  button.gvButton{
    font-size: $fontSizeM;
    padding: 2px 5px;
    border: none;
    background: none;
}

div.BrowserApp div#cdate-slider-container input.input,
div.BrowserApp div#date-slider-container input.input {
    width: 160px;
} 

div.BrowserApp div#cdate-slider-container div.filter,
div.BrowserApp div#date-slider-container div.filter {
    display: inline-block;
    margin-bottom: 10px;
} 

div.BrowserApp div#cdate-slider-container div.filter + div.filter,
div.BrowserApp div#date-slider-container div.filter + div.filter {
    margin-left: 20px;
}

div.BrowserApp div.listNavTop,
div.BrowserApp div.listNavBottom {
    text-align: center;
}

div.BrowserApp div.listNavBottom button {
    margin-left: auto;
    margin-right: auto;
}

div.BrowserApp div.listNavTop span.position {
    display: block;
    font-size: $fontSizeL;
    margin: 5px auto;
    color: #a8b4c7;
    width: 100%;
}

div.BrowserApp div.listNavTop button:hover,
div.BrowserApp div.listNavBottom button:hover {
    color: #fff;
    border-color: #fff;
}

div.BrowserApp div.gvOptionsMenu {
    width: 160px;
}



div.timelineVisual div.scaleControlButtons {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

div.timelineVisual div.scaleControlButtons > button {
    border-radius: 16px;
    width: 32px;
    height: 32px;
    display: inline-block;
    padding: 0;
    outline: none;
}

div.timelineVisual div.scaleControlButtons > button.disabled {
    cursor: default;
}
