@import "_config.scss";

div.MIHelper {
    position: fixed;
    bottom: 0px;
    right: 0px;  
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: $fadedBackground;
}



div.MIHelper > div.cont {
    margin-top: 120px;
    width: 458px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-height: calc(100% - 120px);
}

div.MIHelper > div.cont div.buttons {
    text-align: right;

}

div.MIHelper > div.cont img {
    width: 100%;
    margin-bottom: 20px;
}

div.MIHelper > div.cont p {
    min-height: 90px;
}


