@import "_config.scss";

div.PlatformSelector {
    position: absolute;
    top: 58px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $alternativeBackground;
}

div.PlatformSelector p.subh,
div.PlatformSelector div.generalTitle p {
    font-size: $fontSizeXL;
    color: #000620;
    margin-bottom: 24px;
}

div.PlatformSelector div.subheader {
    background: $panelBG;
    padding-top: 22px;
    padding-bottom: 22px;

}

div.PlatformSelector div.previewHighlights img {
    width: 100%;
}

div.PlatformSelector div.fullSize {
    top: 56px;
}

div.PlatformSelector div.myPlatformCard {
    margin-bottom: 40px;
}

div.PlatformSelector div.myPlatformCard > div {
    background: $cellBackground;
    border-radius: $stdBorderRadius;  
    height: 100%;
    border: $cellBorder;
}

div.PlatformSelector div.myPlatformCard > div > div {
    padding: 14px 0;
    margin-left: 0;
    margin-right: 0;
}


div.PlatformSelector div.myPlatformCard.upcoming {
    opacity: 0.6;
}

div.PlatformSelector div.myPlatformCard > div {
    -webkit-box-shadow: $shadowSizes $shadowColor;
    -moz-box-shadow: $shadowSizes  $shadowColor;
    box-shadow: $shadowSizes  $shadowColor;
}

div.PlatformSelector h4.platformTitle {
    margin-bottom: 14px;
    margin-right: 41px;
    font-size: $fontSizeXXL;
    font-weight: 700;
}

div.PlatformSelector p.platformDescription {
    color: $greyTextColor;
    font-size: $fontSizeM;
    min-height: 42px;
}

div.PlatformSelector p.platformSources {
    min-height: 42px;
}

div.PlatformSelector span.trialConditions {
    color: $greyTextColor;
    font-size: $fontSizeM;
    font-weight: 600;
}

div.PlatformSelector div.priceField span.monthly {
    font-size: 30px;
    color: $h4Color;
    font-weight: 600;
}

div.PlatformSelector div.priceField div.yearlyContainer {
    display: inline-block;
    margin-left: 5px;
}

div.PlatformSelector div.priceField div.monthlyLabel {
    font-size: 14px;
    color: $h4Color;
    font-weight: 600;
    margin-bottom: -4px;
}

div.PlatformSelector div.priceField div.yearly {
    font-size: 10px;
}


div.PlatformSelector div.ScrollbarsCustom-Wrapper {
    right: 0px !important;
    
}

div.PlatformSelector div.proposalCard > div {
    padding: 15px;
    border: 1px solid #8a9bb1;
    border-radius: $stdBorderRadius;
    margin-bottom: 10px;
}

div.PlatformSelector div.proposalCard label {
    font-weight: 600;
}

div.PlatformSelector div.proposalCard input {
    margin-top: 3px;
}

div.pCard h3{
    font-size: $fontSizeXXL;
    color: #0f5174;
}

div.pCard {
    color: #424648;
    font-size: $fontSizeXL;
}

div.pCard > div {
    -webkit-box-shadow: 0px 6px 14px 3px rgba(15,45,71,0.5); 
    box-shadow: 0px 6px 14px 3px rgba(15,45,71,0.5);
}

div.pCard img {
    height: 50px;
    margin-bottom: 20px;
}

div.pCard ul li {
    list-style-position: outside;
}

div.pCard ul.checkboxes li {
    list-style: none;
}
div.pCard ul.checkboxes li::before {  
    content: '';
    display: inline-block;
    height: 29px;
    width: 20px;
    background-image: url('../img/bullet.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    margin-right: 5px;
}

div.pCard div.tab-pane {
    padding-top: 20px;
}

div.pCard a.nav-item {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 5px solid transparent;
}

div.pCard a.nav-item.active {
    color: #495057;
    background-color: #fff;
    border-bottom-color: #dee2e6;
}

div div div.PlatformSelector button.profileBtn,
div div div.PlatformSelector button.profileBtn.dropdown-toggle {
    -webkit-box-shadow: none; 
    box-shadow: none;
    background: #e0e9ed;
    color: $h4Color;
    border: none;
    font-size: $fontSizeM;
    font-weight: 600;
    line-height: 48px;
}

div.PlatformSelector button.profileBtn span.avatar {
    margin-left: 10px;
}

div.PlatformSelector button.profileBtn::after {
    content: none;
}

div.PlatformSelector div.bringYourData {
    background: #fff;
}

div.PlatformSelector div.bringYourData h3.cHeader {
    color: #1F6998;    
}

div.PlatformSelector div.bringYourData p {
    margin-bottom: 0;
}

div.PlatformSelector div.bringYourData ul.checkboxes {
    margin-bottom: 50px;
}

div.PlatformSelector div.bringYourData a.orange {
    position: absolute;
    bottom: 30px;
}

span.noVAT {
    opacity: 0.5;
    font-size: 10px;
}

div.PlatformSelector div.myPlatformCard span.banner {
    position: absolute;
    right: 10px;
    color: #ff5a1a;
    background-color: #ffE7DC;
    display: inline-block;
    padding: 2px 4px;
    border-radius: $stdBorderRadius;
}

div.PlatformSelector div.accordion div {
    border: none;
    background: none;
}


div.PlatformSelector div.accordion div.card-header {
    padding: 0;
}

div.PlatformSelector div.accordion div.card-header button {
    border: none;
    border-radius: 0;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 0;
    text-decoration: none;
    text-transform: uppercase;
    color: #cccdd2;
    border-bottom: 1px solid #cccdd2;
    font-size: $fontSizeXXL;

}

div.PlatformSelector div.accordion div.card-header button.active,
div.PlatformSelector div.accordion div.card-header button:hover {
    color: #000620;
    border-bottom: 1px solid #000620;
    background: none;
}

div.PlatformSelector div.accordion div.card-header button.active {
    border-bottom: 1px solid #2194D5;
}

div.PlatformSelector div.accordion div.card-body {
    padding: 0;
    font-size: $fontSizeXL;
}

div.clients {
    margin-top: 60px;
    margin-bottom: 60px;
}

div.clients img {
    max-height: 61px;
}

div.clients div {
    text-align: center;
    line-height: 61px;
}

div.clients div.clientsheader {
    color:#94A7B7;
    margin-bottom: 10px;
}

div.platformsSectionHeader {
    text-align: center;
    color: #1F6998;
}

div.platformsSectionHeader h3 {
    color: #1F6998;
}

div.PlatformSelector a.goToPlatformButton {
    position: fixed;
    bottom: 50%;
    z-index: 100;
    left: 0;
    width: 100px;
    text-align: center;
    margin-bottom: 0;
    border-radius: 0px 100px 100px 0px;
    margin-left: 0px;
    height: 150px;
    padding-top: 45px;
    display: none;
}


div.PlatformSelector div.accordion div.card-header button.accordionBtn {
    background: none;
}

div.PlatformSelector div.subheader {
    border-bottom: none;
}

div.PlatformSelector p.val {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 0;
}

div.PlatformSelector p.lab {
    font-size: 16px;
}

div.ctaBanner {
    background: linear-gradient(90deg, #2C84C4 22.01%, #309AD6 87.05%);
    border-radius: 6px;
    padding: 30px 40px;
    color: #fff;
    margin-top: -61px;
}

div.ctaBanner h3 {
    color: #fff;
}

div.ctaBanner p {
    margin-bottom: 0;
}

div.ctaBanner a.ctaBtn,
div.ctaBanner button.ctaBtn {
    float: right;
    color: #1F6998;
    background: #fff;
    border: none;
    vertical-align: middle;
    margin-top: 10px;

}

div.ctaBanner a.ctaBtn:hover,
div.ctaBanner button.ctaBtn:hover {
    background: #f1f1f1;
}

div.platformsRow {
    padding-bottom: 61px;
}

div.SubscriptionSelector div.popup {
    top: 110px ;
    bottom: 55px;
    max-width: 420px;
    position: absolute;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background: #EFEFEF;
    border-radius: 10px;
    padding: 20px;
}

div.SubscriptionSelector div.popup div.myCBPlatformCard {
    background: #fff;
    margin-top: 5px;
    border-radius: 6px;
    padding: 12px 0;
}

div.SubscriptionSelector div.popup div.myCBPlatformCard:hover {
    background: #CED6DA;
}

div.SubscriptionSelector div.popup div.myCBPlatformCard span.src {
    color: #595D6B;
    font-size: 12px;
    display: block;
}

div.SubscriptionSelector div.buttons {
    text-align: right;
    border-top: 1px solid #D0D0D0;
    margin-top: 20px;
    margin-left: -15px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: calc(100% + 30px);
}

div.SubscriptionSelector div.buttons button {
    margin-right: 0;
}

div.PlatformSelector div.generalTitle a.button,
div.PlatformSelector div.generalTitle button {
    margin-left: 0;
    border-radius: 2px;
}

div.PlatformSelector div.generalTitle a.lightBtn {
    background: #fff;
    color: #ff5a1a;
}

div.PlatformSelector div.generalTitle a.lightBtn:hover {
    background: #f1f1f1;
    color: #ff5a1a;
    border-color:  #ff5a1a;
}