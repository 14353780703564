@import "_config.scss";

nav.navbar {
    background: $headerBackground;
    border-bottom: $headerBorder;
    background: $panelBG !important;
    padding: 0;
}

div.PlatformSelector div.subheader {
    border-top: 0;
}

nav.navbar div.container-fluid > .row {
    width: 100%;
    margin: 0;
}

div.login nav.navbar div.container-fluid > .row {
    border-bottom: 2px solid $panelBG;
}

nav.navbar div.container-fluid > .row > .col-md-3,
nav.navbar div.container-fluid > .row > .col-md-9 {
    padding: 0;
}

nav.navbar h4 {
    margin-bottom: 0;
    margin-top: 5px;
    text-align: right;
}

nav.navbar a.navbar-brand {
    padding: 12px 0 14px;
}

nav.navbar img.logo {
    height: 19px;
}

nav.navHub.navbar-light .navbar-nav  a {
    font-size: $fontSizeXL;
    color: $navbarLinkColor;
    padding: 16px 20px;
}

nav.navbar.navbar-light .navbar-nav  a.active {
    font-weight: bold;
}

nav.navbar.navbar-light .navbar-nav  a:hover {
    color: $navbarLinkHoverColor;
}

nav.navbar.navbar-light .navbar-nav a.button,
nav.navbar.navbar-light .navbar-nav button {
    color: $navbarBtnColor;
    background: $navbarBtnBG;
    border: none;
    outline: none;
    margin-right: 0;
    padding: 6px 20px;
    margin: 10px 0 10px 4px;
}

nav.navbar.navbar-light .navbar-nav  a.button:hover,
nav.navbar.navbar-light .navbar-nav  button:hover {
    color: $navbarBtnHoverColor;
    background: $navbarBtnHoverBG;
    -webkit-box-shadow: none; 
    box-shadow: none;
}


span.newBanner {
    color: #fff;
    text-transform: uppercase;
    font-size: 8px;
    display: inline-block;
    background: $mainBtnBg;
    padding: 2px 3px 0 3px;
    vertical-align: top;
    margin-left: 2px;
    font-weight: normal;
}