@import "_config.scss";

.doc-comment-form {
    form {
        textarea {
            width: 100%;
            resize: none;
        }

        .actions {
            text-align: right;
            button {
                min-width: 100px;

                &:first-of-type {
                    margin-right: 1em;
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}


.doc-comment-add-dialog  {

    position: absolute;
    top: 0;
    left: 0;
    min-width: 300px;

    border: 1px solid rgba(0,0,0,.2);
    background-color: #fff;
    background-clip: padding-box;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 29px 3px rgba(85, 85, 85, 0.66);

    display: none;

    &.open {
        display: block;
        z-index: 50000;
    }

    .doc-comment-form {
        form {
            .actions {
                width: 100%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                button {
                    flex: 1;

                    /*&:first-of-type {
                        margin-right: 1em;
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }*/
                }
            }
        }
    }
}

.doc-comments-container {
    position: absolute;
    top: 52px; left: 0; bottom: 0;
    width: 41%;

    display: none;
    &.open {
        display: block;
    }

    z-index: 31000;

    .doc-comments-overlay {
        position: absolute;
        top: 0; left: 0; bottom: 0;
        width: 100%;
        background: url("../img/overlay-2x2-px.png");
        backdrop-filter: blur(3px);
    }

    .doc-comments {
        position: absolute;
        top: 50px; right: 0; bottom: 0;
        width: 70%;
        min-width: 300px;
        overflow-y: hidden;
        padding: 0 10px 0 0;

        .ScrollbarsCustom-Wrapper {
            margin: 0 0;
        }
        .ScrollbarsCustom-Content {
            padding: 10px 20px !important;
        }

        .doc-comment {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0px 0px 10px 3px rgba(85, 85, 85, 0.66);

            border: 1px #fff solid;
            padding: .725rem .725rem .725rem 1rem;

            margin: 0 0 1.5rem 0;

            &:hover {
                border: 1px #1E90FF solid;
                box-shadow: 0px 0px 10px 3px rgba(30, 144, 255, 0.66);
            }

            .comment-header {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;

                .avatar {
                }
                .name-and-date {
                    margin-left: .5rem;
                    .name {
                        font-size: $fontSizeL;
                    }
                    .date {
                        font-size: $fontSizeS;
                        color: $fontColorGrey;
                    }
                }
                .menu {
                    //align-self: flex-end !important;
                    margin-left: auto;

                    .dropdown {
                        .dropdown-toggle {
                            &:after {
                                display: none;
                            }

                            box-shadow: none;
                            margin: 0;
                            padding: .375rem .5rem;
                            text-align: center;
                            > svg.dots-alone {
                                margin-right: 0;
                            }
                        }
                        .dropdown-menu {
                            //padding: 0;
                        }
                    }
                }
            }

            .comment-body {
                margin-top: .375rem;
                padding: .2rem;
            }
        }
    }

}
