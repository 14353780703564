
div.docViewer div.textContent .meta-tag {
    display: inline;
    &.must-include {
        background: $docMIBG;
    }
    &.highlight {
        display: inline;
        cursor: pointer;
        border-bottom: 2px solid #ffda00;

        &.highlight-painted {
            background: #70bfff !important;
        }

        &.highlight-with-comments {
            background: #ffda00;
        }

    }
}


@media print {
    div#contentstoprint .meta-tag {
        display: inline;
        &.must-include {
            background: $docMIBG;
        }
        &.highlight {
            display: inline;
            cursor: pointer;
            border-bottom: 2px solid #ffda00;

            &.highlight-painted {
                background: #70bfff !important;
            }
    
            &.highlight-with-comments {
                background: #ffda00;
            }
        }
    }
}