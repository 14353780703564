@import "_config.scss";

div.GHelper {
    position: fixed;
    bottom: 30px;
    right: 30px; 
}

div.BrowserApp > div.GHelper {
    right: auto;
    left:calc(41% - 60px);
}

div.queryForm > div.GHelper {
    position: absolute;
    bottom: 10px;
    
}

div.queryForm > div.GHelper a,
div.queryForm > div.GHelper span,
div.BrowserApp > div.GHelper a,
div.BrowserApp > div.GHelper span {
    display: none;
}



div.GHelper > div.cont {
    background: #3f75f1;
    border-radius: 19px;
}

div.GHelper button,
div.GHelper a {
    border-radius: 16px;
    border: none;
    padding: 4px;
    margin: 3px;
    background: none;
    display: inline-block;
    outline: none;
}

div.GHelper button:hover,
div.GHelper a:hover {
    background: #104ca1;
}



div.GHelper > div.cont img {
    width: 24px;
    margin: 0;
}

div.GHelper span.hsep {
    display: inline-block;
    background-color: #fff;
    width: 1px;
    height: 14px;
    margin-bottom: -3px;
    margin-left: 4px;
    margin-right: 4px;
    opacity: 0.2;
}



