@import "_config.scss";

div.exportDocsSelectorsPanel {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(68,68,68,0.866);
  z-index: 35000;
  
  -webkit-transition: 1.0s;
  -moz-transition: 1.0s;
  -o-transition: 1.0s;
  transition: 1.0s;
    
  opacity: 0;
  visibility: hidden; 
}

div.exportDocsSelectorsPanel.show {
  opacity: 1;
  visibility: visible;

}


div.exportDocsSelectorsPanel div.panel {
  background: #fff;
  max-width: 100%;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-top: 80px;
  max-height: 70%;
  overflow-y: auto;
  border-radius: $stdBorderRadius;
}

div.exportDocsSelectorsPanel button {
    margin-left: 0;
}

div.exportDocsSelectorsPanel div.panel ul {
    padding-left: 0;
}

div.exportDocsSelectorsPanel div.panel ul li {
  list-style: none;
}


div.savedDocsViewer {
    flex: 0 1 41%;
    background: #fff;
    display: flex;
    flex-flow: column;
    
}


div.savedDocsViewer div.cardsContainer {
    flex: 1 1 auto;
    background: #fff;
}

div.savedDocsViewer div.subheader span.hSeparator {
    position: absolute;
    top: 0px;
    right: -20px;
    height: 39px;
}

div.savedDocsViewer div.docCard span.flags {
    position: absolute;
    margin-left: -25px;
    margin-top: -1px;
    width: 20px;
    line-height: 12px;
}


div.savedDocsViewer div.docCard svg.listItemReadIcon {
    width: 16px;
    margin-left: -4px;
}

div.savedDocsViewer div.docCard span.flags svg {
    margin-right: 3px;
}

div.savedDocsViewer div.docCard.savedDocCard {
    padding-bottom: 15px;
}