.highlight-menu {
    position: absolute;
    top: 100px;
    left: 50px;

    padding: 0;

    border: 1px solid rgba(0,0,0,.2);
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    box-shadow: 0px 0px 29px 3px rgba(85, 85, 85, 0.66);

    z-index: 50000;

    min-width: 140px;

    display: none;
    &.open {
        display: block;
    }
}
