@import "_config.scss";

div.nheader {
    background: $headerBackground;
    border-bottom: $headerBorder;
    min-height: 56px;
}

div.nheader div.optionsMenu {
    z-index: 50000;
}

div.nheader a {
    vertical-align: top;
}

div.nheader svg.logo {
    height: 20px;
    margin-top: 17px;
    margin-left: 8px;
    margin-right: 20px;
}

div.nheader a.button,
div.nheader button {
    color: $hLinkColor;
    background: $transparentBtnBG;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
}

div.nheader button.profileMenuBtn + div.optionsMenu > a.button {
    height: 37px;
}

div.nheader a.button:hover,
div.nheader a.button.hovered,
div.nheader button:hover,
div.nheader button.hovered {
    color: $transparentBtnHoverColor;
    background: $transparentBtnHoverBG;
    border: none;
    outline: none;
}


div.nheader button .sb-avatar {
    margin-right: 2px;
    margin-left: 10px;
}

div.nheader div.optionsMenu {
    right: 21px;
    margin-top: 8px;
}

div.subheader {
    background: $subheaderBackground;
    vertical-align: top;
    border: $subheaderBorder;
    min-height: 40px;
}

div.subheader button,
div.subheader span {
    vertical-align: top;
}

div.nheader button.transp,
div.nheader a.button.transp {
    margin-top: 12px;
    font-size: $fontSizeM;
}

div.BrowserApp div.visualConfigPanel button,
div.filtersPanel div.platformFilters button,
div.nheader button.transp,
div.nheader a.button.transp,
div.subheader button,
div.subheader a.button {
    color: $transparentBtnColor;
    background: $transparentBtnAlternativeBG;
    border: $transparentBtnAlternativeBorder;
    outline: none;
    padding: 4px 10px;
}

div.BrowserApp div.subheader button,
div.BrowserApp div.subheader a.button {
    background: $transparentBtnAlternativeBG;
    border: $transparentBtnAlternativeBorder;
}

div.BrowserApp div.visualConfigPanel button:hover,
div.filtersPanel div.platformFilters button:hover,
div.nheader button.transp:hover,
div.nheader a.button.transp:hover,
div.BrowserApp div.subheader button:hover,
div.BrowserApp div.subheader button.hovered {
    background: $transparentBtnHoverBG;
}

div.filtersPanel div.platformFilters button:hover,
div.nheader button.transp:hover,
div.nheader a.button.transp:hover,
div.subheader button:hover {
    color: $transparentBtnHoverColor;
    background: $transparentBtnHoverBG;
    outline: none;
}

div.filtersPanel div.platformFilters button.hovered,
div.subheader button.hovered {
    color: $transparentBtnActiveColor;
    background: $transparentBtnActiveBG;
    border: $transparentBtnActiveBorder;
    outline: none;
}

div.omContainer > button.dropdown-toggle::after {
    content: none;
}

div.omContainer.show > button.dropdown-toggle.btn-light ,
div.filterDropdown.nonTrivial > button.dropdown-toggle.btn-light ,
div.filterDropdown.show > button.dropdown-toggle.btn-light ,
div.filtersPanel div.platformFilters button.active,
div.nheader button.transp.active,
div.nheader a.button.transp.active,
div.BrowserApp div.subheader button.active {
    color: $transparentBtnActiveColor;
    background: $transparentBtnActiveBG;
    border: $transparentBtnActiveBorder; 
}

div.filtersPanel div.platformFilters button.disabled,
div.BrowserApp div.subheader button.disabled,
div.BrowserApp div.subheader button.disabled:hover {
    opacity: 0.6;
    cursor: default;
    color: $transparentBtnHoverColor;
    background: $transparentBtnHoverBG;
    
}

div.docViewer div.subheader div.alignLeft button:first-child {
    margin-left: 0;
}

div.docViewer div.subheader div.alignRight button:last-child {
    margin-right: 0;
}

div.subheader div.alignRight {
    float: right;
}

div.filtersPanel div.platformFilters button.active,
div.subheader button.active {
    color: $transparentBtnActiveColor;
    background: $transparentBtnActiveBG;
    border: $transparentBtnActiveBorder;
}

div.filtersPanel div.platformFilters button,
div.BrowserApp div.subheader button,
div.BrowserApp div.subheader a.button {
    font-size: $fontSizeM;
    padding: 2px 5px;
}

div.subheader span.hSeparator {
    margin: 6px 20px;
    background: $separatorColor;
    display: inline-block;
    width:  1px;
    height: 33px;
    border-radius: 1px;
}

div.nheader div.rightNav {
    display: inline-block;
    float: right;
}

div.nheader div.projectNavigation {
    display: inline-block;
    margin-top: 8px;
    border-radius: 18px;
}

div.nheader div.projectNavigation button {
    background: $projectNavBtnBG;
    color: $projectNavBtnColor;
    border: none;
    margin: 4px;
    padding: 3px 5px;
    border-radius: 14px;
}

div.nheader div.projectNavigation button:hover {
    background: $projectNavBtnHoverBG;
    color: $projectNavBtnHoverColor;
    border: none;
}

div.nheader div.projectNavigation button.active {
    background: $projectNavBtnActiveBG;
    color: $projectNavBtnActiveColor;
    border: none;
}

div.nheader div.projectNavigation span.counter {
    display: inline-block;
    color: #1e86ff;
    background: #d0e4ff;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: $stdBorderRadius;
    margin-left: 5px;

}

div.nheader a.button svg {
    margin-top: -1px;
    margin-bottom: -1px;
}

div.nheader a.button {
    height: 28px;
}

div.timelineContainer div.subheader button,
div.listContainer div.subheader button,
div.netContainer div.subheader button {
    min-width: 90px;
}

div.BrowserApp button.resultsViewTypeBtn + button.resultsViewTypeBtn {
    border-left: none;
}   

div.BrowserApp button.resultsViewTypeBtn + button.resultsViewTypeBtn.active {
    border-left: $transparentBtnActiveBorder;
}

button.searchBtn > div {
    position: absolute;
    z-index: 31000;
    background: $panelBG;
    color: $queryTextAreaColor;
    top: 52px;
    text-align: left;
    padding: 15px;
    border-radius: $stdBorderRadius;
    display: none;
    border: $subheaderBorder;
    
}

button.searchBtn:hover > div {
    display: block;
}

button.searchBtn.active:hover > div {
    display: none;
}

button.searchBtn > div div.mQuery {
    margin-bottom: 10px;
    max-height: 400px;
}

div div.nheader div button.projectBtn {
    height: 34px;
    max-width: calc(100% - 135px);
    margin-top: 8px;
    padding: 0 6px;
    border: none;
    overflow: hidden;
    text-align: left;
}

div div.nheader div button.projectBtn span.projectDesc,
div div.nheader div button.projectBtn span.projectName {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    display: block;
}

div div.nheader div button.projectBtn span.projectName {
    color: $transparentBtnActiveColor;
    font-weight: 500;
    font-size: $fontSizeL;
}

div.nheader div.trialEnd {
    margin-top: 5px;
    display: inline-block;
}

div.nheader div.trialEnd span {
    font-size: $fontSizeXL;
    height: 48px;
    line-height: 48px;
    display: inline-block;
}

div.nheader div.trialEnd a.button {
    height: 38px;
}

div.notificationsMenuContainer,
div.profileMenuContainer {
    display: inline-block;
}

button.notificationsMenuBtn::after {
    display: none;
}

div.nheader button.notificationsMenuBtn {
    padding: 0;
    margin-right: 0;
    border-radius: 16px;
}

button.notificationsMenuBtn svg {
    width: 29px;
    margin: 0;
}

div.notificationsMenuContainer div.optionsMenu {
    width: 600px;
    height: 400px;
    overflow: auto;
}

div.notificationsMenuContainer div.head {
    background: #f2f3f7;
    border-bottom: 1px solid $panelBorderColor;
}

div.notificationsMenuContainer div.head button {
    display: inline-block;
    width: auto;
    margin-left: 10px;
    border-radius: $stdBorderRadius;
    background: none;
}

div.notificationsMenuContainer div.head button.active {
    background: $transparentBtnActiveBG;
}

div.notificationsMenuContainer div.notificationCard > div {
    margin: 5px;
    padding: 10px 20px;
    font-size: $fontSizeL;
}

div.notificationsMenuContainer div.notificationCard.unread > div {
    background: #f2f3f7;
    cursor: pointer;
}

div.notificationsMenuContainer div.notificationCard .notificationIconContainer,
div.notificationsMenuContainer div.notificationCard svg.notificationIcon{
    position: absolute;
    width: 30px;
}

div.notificationsMenuContainer div.notificationCard div.desc {
    margin-left: 55px;
}

div.notificationsMenuContainer div.notificationCard div.desc div.row div.col-md-2 {
    color: #c6cbd8;

}

div.notificationsMenuContainer div.notificationCard div.desc div.row div.col-md-10 {
    color: #474747
}


div.notificationsMenuContainer div.notificationCard div.desc div.row div.col-md-5 {
    color: #868a95
}

div.notificationsMenuContainer h4 {
    margin: 20px 20px;
}

div.notificationsMenuContainer div.notificationCard + div.notificationCard {
    border-top: 1px solid #c0c9d6;
}

span.unreadNotificationsPin {
    display: inline-block;
    background-color: #ff0000;
    border: 1px solid #fff;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: -7px;
    position: absolute;

}

span.logoSmallContainer {
    display: inline-block;
    margin-top: 12px;
    margin-right: 16px;
    padding: 4px;
    border-radius: 4px;
}

span.logoSmallContainer span.arrow {
    opacity: 0;
}

span.logoSmallContainer:hover span.arrow {
    opacity: 1;
}

span.logoSmallContainer:hover {
    background: #f2f2f2;
    
}

div.nheader svg.logosmall {
    margin-top: 0;
    margin-right: 0px;
}