@import "_config.scss";

div.ProjectSelectorContainer {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $fadedBackground;
}

div.ProjectSelectorContainer.popupMode {
    z-index: 35000;
    position: fixed;
    top: 0;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 80px;
}

div.ProjectSelectorContainer.popupMode div.subheader button {
    padding: 4px 10px;
}


div.ProjectSelector {
    width: 100%;
    height: 100%;
    background: $alternativeBackground;
    display: flex;
    flex-flow: column;
}

div.ProjectSelector div.subheader {
    position: relative;
    min-height: 45px;
}

div.ProjectSelector div.fillSize {
    width: 100%;
    height: 100%;
}




div.ProjectSelectorContainer.popupMode div.qsBar {
    display: none;
}

div.ProjectSelector div.qsBar {
    height: 148px;
    background-image: url(../img/qsBar.jpg);
    background-size: cover;
    background-position: bottom;
    padding-top: 38px;
}

div.ProjectSelector div.qsBar div.qs {
    cursor: pointer;
    width: 690px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    border-radius: $stdBorderRadius;
    font-size: $fontSizeXL;
    padding: 0px ;
    color: #9BA1B7;
    -webkit-box-shadow: 0px 7px 14px 0px rgba(12,21,67,0.3); 
    box-shadow: 0px 7px 14px 0px rgba(12,21,67,0.3);
    border: 1px solid #fff;
}

div.ProjectSelector div.qsBar div.qs:hover {
    border: $queryTextAreaBorder;
}

div.ProjectSelector div.qsBar div.qs span {
    display: inline-block;
    width: 100%;
    border-radius: $stdBorderRadius;
    padding: 10px 10px ;
    
}


div.ProjectSelector div.qsBar div.qs svg {
    height: 20px;
    margin-top: -1px;
}

div.ProjectSelector div.myProjectCard {
    margin-bottom: 40px;
}

div.ProjectSelector div.myProjectCard div.flags {
    padding-top: 6px;
}

div.ProjectSelector div.myProjectCard > div {
    padding: 14px 0;
    background: $cellBackground;
    border-radius: $stdBorderRadius;  
    cursor: pointer;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    border: $cellBorder;
}

div.ProjectSelector div.myProjectCard > div:hover {
    -webkit-box-shadow: $shadowSizes $shadowColor;
    -moz-box-shadow: $shadowSizes  $shadowColor;
    box-shadow: $shadowSizes  $shadowColor;
}

div.ProjectSelector div.newProjectCard > div:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

div.ProjectSelector h4.projectTitle {
    margin-bottom: 14px;
    margin-right: 41px;
}

div.ProjectSelector div.myProjectCard div.omContainer {
    position: absolute;
    top: 0px;
    right:18px;
    z-index: 999;
}

div.ProjectSelector div.myProjectCard div.omContainer > button svg {
    margin-right: 0;
}



div.ProjectSelector div.sharedPanel button.shareBtn {
    margin: 0;
    font-size: $fontSizeM;
    font-weight: 300;
    outline: none;
    padding-left: 7px;
    margin-left: -5px;
}

div.ProjectSelector div.sharedPanel div.sb-avatar:first-child {
    margin-left: 0px;
}

div.ProjectSelector div.sharedPanel div.sb-avatar {
    margin-top: -2px;
    margin-right: 15px;
    margin-left: -22px;
}

div.ProjectSelector div.sharedPanel div.sb-avatar > div {
    border: 1px solid #fff;
}


div.ProjectSelector div.newProjectCard > div {
    background: none;
    border: 2px dotted $dashedBorderColor;
}

div.ProjectSelector div.newProjectCard > div:hover {
    border: 2px dotted $dashedBorderColorHover;
}



div.ProjectSelector div.newProjectCard > div > div > div > svg {
    width: 30px;
    height: 30px;
    display: inline-block;
}

div.ProjectSelector div.newProjectCard > div > div > div {
    vertical-align: middle;
    display: inline-block;
}


div.ProjectSelector div.newProjectCard h3 {
    color: $dashedBorderColor;
    margin-bottom: 3px;
    margin-top: 8px;
}

div.ProjectSelector div.newProjectCard:hover h3 {
    color: $dashedBorderColorHover;
}

div.ProjectSelector div.newProjectCard span {
    color: $dashedBorderColor;
    opacity: 0.8;
}
div.ProjectSelector div.newProjectCard:hover span {
    color: $dashedBorderColorHover;
}

div.ProjectSelector div.optionsMenu button.deleteBtn {
    background:$roseBackground;
    color: $redAccentColor;
}

div.ProjectSelector div.optionsMenu button.deleteBtn:hover {
    background: $roseBackgroundHover;
    
}


div.ProjectSelector div.headers {
    display: none;
}

div.ProjectSelector div.NewProject div.whitePanel {
    padding: 20px 30px;
}


div.ProjectSelector div.NewProject input {
    border: 1px solid $inputTintColor;
    border-radius: 4px;
    background: $inputBackground;
    width: 320px;
    max-width: 100%;
    line-height: 21px;
}

div.ProjectSelector div.NewProject button {
    width: 121px;
    margin-left: 20px;
}

div.ProjectSelector button.selectorBtn {
    background: none;
    border: none;
    margin-right: 0;
    margin-left: 20px;
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0px 0 0px 0;
    outline: none;
}

div.ProjectSelector button.selectorBtn svg {
    margin-right: 0;
}

div.ProjectSelector div.ScrollbarsCustom-Wrapper {
    right: 9px !important;
    
}

div.ProjectSelector div.ScrollbarsCustom-Wrapper div.ScrollbarsCustom-Scroller {
    margin-right: -1px;
    
}


div.ProjectSelector div.popupModeHeader span.captionSpan {
    margin-bottom: 15px;
    border-bottom: 1px solid $hSeparatorColor;
    display: block;
}

div.ProjectSelector div.popupModeHeader span.captionSpan h3.caption {
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-block;
    border-bottom: $transparentBtnActiveThickBorder;
    margin-bottom: 0;
    margin-left: 21px;
    margin-right: 20px;
}

div.ProjectSelector div.popupModeHeader span.docCaptionSpan {
    display: block;
    margin-left: 21px;
    margin-right: 20px;
    color: $docCardTitleColor;
    font-size: $fontSizeL;
    font-weight: bold;
    margin-bottom: 15px;
}
