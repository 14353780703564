@import "_config.scss";

div.acceptTerms {
    position: absolute;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;  
    background: #565656ee;
    z-index: 100000;
}

div.acceptTerms a {
    text-decoration: underline;
}

div.acceptTerms a:hover {
    text-decoration: none;
}

div.acceptTerms div.fullSize {
    top: 56px;
    padding-top: 60px;
    
}

div.acceptTerms div.fullSize > div {
    background: $alternativeBackground;
    border-radius: $stdBorderRadius;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    height: 600px;
    max-height: calc(100%-200px);
    max-width: 100%;
}


div.acceptTerms button {
    margin-left: 0 !important;
}

div.acceptTerms div.stdTopPadded {
    padding: 30px 30px;
}