@import "_config.scss";

.doc-annotation {
    .annotation-buttons {
        margin-bottom: 5px;
        > button {
            height: 28px;
            font-weight: normal;
            padding: 2px 10px;
            font-size: 15px;
            border-radius: 13px;

            &:first-child {margin-left: 0;}
            &:last-child {margin-right: 0;}
            &:disabled {
                opacity: .25;
                cursor: default;
            }

            > svg {
                height: 18px;
                width: 18px;
                margin: -2px 5px 0 2px;
                &.icon-active {display: none;}
                &.icon-inactive {display: inline;}
            }

            &.active {
                padding: 1px 9px;
                > img {
                    &.icon-active {display: inline;}
                    &.icon-inactive {display: none;}
                }
            }

            &.grey {
                color: #8C96A7;
                background-color: #EDEDF0;
                border: solid 1px #EDEDF0;

                > span.viewed {display: none;}
                > span.not-viewed {display: inline;}

                &.viewed {
                    color: #fff;
                    background-color: #8C96A7;

                    > span.viewed {display: inline;}
                    > span.not-viewed {display: none;}
                }

                &.active {
                    color: #F7F7FA;
                    background-color: #8C96A7;
                    border: solid 1px #8C96A7;
                }
            }

            &.green {
                color: #37833C;
                background-color: #fff;
                border: solid 1px #37833C;
                &.active {
                    background-color: #EDF7EE;
                    border-width: 2px;
                }
            }

            &.yellow {
                color: #FFCB34;
                background-color: #fff;
                border: solid 1px #F0B60F;
                &.active {
                    color: #ffbb00;
                    background-color: #fff9eb;
                    border-width: 2px;
                }
            }

            &.red {
                color: #C8382D;
                background-color: #fff;
                border: solid 1px #C8382D;
                &.active {
                    background-color: #f5eceb;
                    border-width: 2px;
                }
            }
        }
    }
    .annotation-text-container {
        padding: 15px 20px;
        border-radius: 2px;
        margin-bottom: 10px;

        .annotation-header {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding-bottom: 10px;
            border-bottom: solid 1px grey;

            color: #616161;

            .avatar {
                .sb-avatar {margin-right: 0;}
            }
            .name-and-date {
                margin-left: .5rem;
                .name {
                    font-size: $fontSizeL;
                }
                .date {
                    font-size: $fontSizeS;
                    //color: $fontColorGrey;
                }
            }
            .menu {
                //align-self: flex-end !important;
                margin-left: auto;

                .dropdown {
                    background-color: inherit;
                    .dropdown-toggle {
                        background-color: inherit;
                        &:after {
                            display: none;
                        }

                        box-shadow: none;
                        margin: 0;
                        padding: .375rem .5rem;
                        text-align: center;
                        > svg.dots-alone {
                            margin-right: 0;
                        }
                    }
                    .dropdown-menu {
                        //padding: 0;
                    }
                }
            }
        }
        .annotation-text {
            padding-top: 5px;
        }

        &.color-grey {
            background-color: #f5f5f5;
            .annotation-text {color: #828282;}
            .annotation-header {border-color: #cccccc;}
        }

        &.color-green {
            background-color: #EDF7EE;
            .annotation-text {color: #37833C;}
            .annotation-header {border-color: #C3CE9E;}
        }

        &.color-yellow {
            background-color: #fff9eb;
            .annotation-text {color: #e6a800;}
            .annotation-header {border-color: #ccbf9d;}
        }

        &.color-red {
            background-color: #f5eceb;
            .annotation-text {color: #C8382D;}
            .annotation-header {border-color: #cca29d;}
        }
    }

    .annotation-form {
        margin-bottom: 10px;
        &.color-grey {
            textarea {
                background-color: #f5f5f5;
                color: #828282;
            }
        }

        &.color-green {
            textarea {
                background-color: #EDF7EE;
                color: #37833C;
            }
        }

        &.color-yellow {
            textarea {
                background-color: #fff9eb;
                color: #e6a800;
            }
        }

        &.color-red {
            textarea {
                background-color: #f5eceb;
                color: #C8382D;
            }
        }
    }
}
