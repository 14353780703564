@import "_config.scss";

div.notification div.bottom h4 {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
}

div.notification div.bottom {
    font-size: $fontSizeL;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 50000;

    background: $lightBlueBG;
    color: $h4Color;
    
  }

div.notification div.bottom.error h4 {
    color: #fff;
} 

div.notification div.bottom.error {
    background: $redAccentColor;
    color: $roseBackground;
    
}

div.notification div.popupContainer {
    background: $fadedBackground;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

div.notification div.popupContainer div.popup {
    width: 400px;
    max-width: 100%;
    max-height: 90%;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background: $panelBG;
    padding: 30px 20px;
    border-radius: $stdBorderRadius;
}

div.notification div.popupContainer div.popup button.closeBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

div.notification  div.deleteSign {
    float: right;
    width: 20px;
    height: 20px;
    margin-top: 1px;
    margin-left: 5px;
    vertical-align: middle;
    background: url('../img/nicon_cross.svg');
    cursor: pointer;
    background-size: 100% 100%;
}

div.notification  div.deleteSign:hover {
    background: url('../img/nicon_cross_active.svg');
}