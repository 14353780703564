@import "_config.scss";

div div.ProjectShare.fullSize {
    background: $fadedBackground;
    z-index: 40000;
    top: 0;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

div.ProjectShare div.contents {
    background: $panelBackground;
    width: 500px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 140px;
    border-radius: $stdBorderRadius;
    height: calc(100% - 180px);
    overflow: show;
}

div.ProjectShare div.contents div.scrollContainer {
    height: calc(100% - 123px);
}


div.ProjectShare span.captionSpan {
    border-bottom: 1px solid $hSeparatorColor;
    display: block;
}

div.ProjectShare h3.caption {
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-block;
    border-bottom: $transparentBtnActiveThickBorder;
    margin-bottom: 0;
    margin-left: 21px;
}

div.ProjectShare h3.projectName {
    display: none;
}

div.ProjectShare h4 {
    padding: 20px;
    margin-bottom: 0;
}


div.ProjectShare div.contents div.userLine {
    padding: 11px 20px;
    color: $hLinkColor;
    clear: both;
    cursor: pointer;
}

div.ProjectShare div.contents div.userLine.self {
    cursor: default;
}

div.ProjectShare div.contents div.userLine:hover {
    background: $linkLikeBtnActiveBG;
}

div.ProjectShare div.contents div.userLine button {
    float: right;
    margin: 0;
    display: none;
    background: $transparentBtnActiveBG;
    color: $transparentBtnActiveColor;
}

div.ProjectShare div.contents div.userLine.existing button {
    background: $roseBackground;
    color: $redAccentColor;
}

div.ProjectShare div.contents div.userLine:hover button {
    display: inline-block;
}

div.ProjectShare div.contents div.userLine span {
    padding-bottom: 4px;
    padding-top: 4px;
    display: inline-block;
}

div.ProjectShare div.contents div.userLine div.sb-avatar {
    margin-right: 15px;
}

div.ProjectShare button.continueBtn {
    width: 488px;
}