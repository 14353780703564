@import "_config.scss";

div.Helper {
    position: fixed;
    bottom: 10px;
    right: 40px;  
    z-index: 10000;
}

div.Helper > div.visual {
    padding: 10px;
    -webkit-transition: 1.0s;
    -moz-transition: 1.0s;
    -o-transition: 1.0s;
    transition: 1.0s;
    animation: shake 1.5s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);    
}

div.Helper > div.visual img {
    width: 0;
    height: 0;
}

  
@keyframes shake {
    10%, 90%{
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80%{
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}


div.Helper > div.cont {
    position: absolute;
    width: 300px;
    height: 200px;
    bottom: 40px;
    right: 10px;
    display: block;
}

div.Helper:hover > div.cont {
    
}

div.Helper div.helperContents {
    background: #fff;
    border: 1px solid $panelBorderColor;
    border-radius: $stdBorderRadius;
    -webkit-box-shadow: $shadowSizes $shadowColor;
    -moz-box-shadow: $shadowSizes  $shadowColor;
    box-shadow: $shadowSizes  $shadowColor;
}

div.Helper div.helperContents div.container-fluid{
    padding: 20px;
    padding-bottom: 0;
}

