@import "_config.scss";


div.loginForm {
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $mainBackground;
}

div.loginForm div.contentsContainer {
    max-height: calc(100% - 150px);
    overflow: auto;
    background: $panelBG;
    width: 374px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    border-radius: $stdBorderRadius;
}

div.loginForm div.contents {
    padding: 32px;
}


div.register div.loginForm div.contentsContainer {
    width: 909px;
    margin-top: 56px;
    max-height: calc(100% - 90px);
    
}

div.register div.loginForm div.contents {
    padding: 0;
}

div.register div.loginForm div.leftPart ul {
    padding: 0;
}

div.register div.loginForm div.leftPart ul li {
    list-style: none;
    margin-bottom: 10px;
}

div.register div.loginForm div.leftPart ul li div {
    display: inline-block;
    width: calc(100% - 32px);
    vertical-align: top;
}

div.register div.loginForm div.leftPart ul li span.l {
    display: block;
    color: #6da2c5;
    font-size: 12px;
}

div.register div.loginForm div.leftPart ul li span.h {
    display: block;
    color: #163f5a;
    font-weight: 600;
}

div.register div.loginForm div.leftPart ul li::before {  
    content: '';
    display: inline-block;
    height: 22px;
    width: 22px;
    background-image: url('../img/bluePin.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    margin-right: 10px;
}



div.register div.loginForm div.leftPart {
    background: $lightBlueBG;
    padding: 49px 58px;
}

div.register div.loginForm div.leftPart img {
    width: 260px;
    max-width: 100%;
}

div.register div.loginForm div.leftPart h3 {
    font-size: $fontSizeXL;
    margin-bottom: 20px;
}

div.register div.loginForm div.rightPart {
    padding: 36px 76px 32px 47px;
}

div.register div.loginForm div.chControl input {
    width: auto;
    margin-right: 10px;
}

div.register div.loginForm a {
    color: $blueLinkColor;
}

div.register div.loginForm div.rightPart img.imecLogo {
    height: 37px;
    margin-top: 20px;
}


div.loginForm button.forgotPassword {
    text-align: right;
    font-size: $fontSizeM;
}

div.loginForm button.backToLogin {
    font-size: $fontSizeM;
}

div.loginForm input {
    width: 100%;
}

div.loginForm button {
    width: 100%;
    margin: 0;
    margin-top: 10px;
}



div.loginForm div.leftPromo {
    float: left;
    height: 100%;
    width: 45%;
}



div.loginForm p.sub {
    color: $semiTransparentTextColor;
    
}

div.loginForm div.rightBar {
    clear: both;
}

div.field + div.field {
    margin-top: 10px;
}

div.loginForm div.noAcc {
    margin-top: 20px;
    border-top: 1px solid $inputTintColor;
    padding-top: 10px;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
}

div.loginForm div.noAcc button.toRegister {
    background-color: #3995C3;
    border: none;
}

div.loginForm div.noAcc button.toRegister:hover {
    background-color: #2683B2;
    border: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(24,97,133,0.4); 
box-shadow: 0px 3px 5px 0px rgba(24,97,133,0.4);
}


@media only screen and (max-width: 768px) {
    div.loginForm div.leftPart {
        display: none;    
    }
  }



