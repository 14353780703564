@import "_config.scss";

div.Monitor nav.navbar {
    background: #204D97 !important;
    border-bottom: none;
}

div.Monitor nav.navbar a.button {
    background: none;
    border: none;
    color: #fff;
}

div.Monitor div.lPart {
    position: absolute;
    width: 284px;
    height: 100%;
    background: #fff;
}

div.Monitor div.lPart div.cont {
    padding: 15px 30px;
}

div.Monitor div.lPart h3 {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
}

div.Monitor div.lPart h4 {
    color: #ADADAD;
    font-size: 12px;
}

div.Monitor div.lPart div.sPlatformCard {
    font-size: 14px;
    cursor: pointer;
    padding: 11px;
    margin-left: -11px;
    margin-right: -11px;
    border-radius: 5px;
}

div.Monitor div.lPart div.sPlatformCard:hover {
    background: #E3E3E3;
}

div.Monitor div.lPart div.sPlatformCard.active {
    background: #C8DDFF;
    color: #2A4E89;
    font-weight: 700;
}

div.Monitor div.lPart .platformsContainer {
    padding-bottom: 10px;
}

div.Monitor div.rPart {
    position: absolute;
    left: 284px;
    height: 100%;
    background: #f1f1f1;
    right: 0;
    padding: 12px 40px;
}

div.Monitor div.rPart div.delaySelectors {
    font-size: 12px;
    border-bottom: 1px solid #B9B9B9;
    padding-bottom: 12px;
    
}

div.Monitor div.rPart div.delaySelector {
    cursor: pointer;
    display: inline-block;
    padding: 8px 10px;
    border-radius: 4px;
}

div.Monitor div.rPart div.delaySelector:hover {
    background: #E3E3E3;
}

div.Monitor div.rPart div.delaySelector.active {
    background: #D2D2D2;
    font-weight: bold;
}

div.Monitor div.rPart div.ScrollbarsCustom {
    height: calc(100% - 59px) !important;
}

div.Monitor div.docCard {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 13px 21px;
}

div.Monitor div.docCard h5 {
    color:#204D97;
    font-size: 12px;
}

div.Monitor div.docCard h4 {
    color:#000;
    font-size: 16px;
}

div.Monitor div.docCard h6 {
    color:#797979;
    font-size: 14px;
    padding-left: 0;
    font-weight: normal;
}

div.Monitor div.docCard span.buttons a {
    background: none;
    border: 1px solid #204D97;
    color: #204D97;
    margin-left: 0;
    margin-right: 20px;
}

div.Monitor div.docCard span.buttons a img {
    height: 12px;
    width: 12px;
    margin-left: 10px;
}

div.Monitor div.docCounter {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
}

div.Monitor div.decisionsSplitController {
    border-top: 1px solid #D9D9D9;
    margin-top: 15px;
    margin-left: -11px;
    margin-right: -11px;
    padding: 15px 0;
}

div.Monitor div.decisionsSplitController h3 {
    margin-left: 11px;
    margin-right: 11px;
}

div.Monitor div.decisionsSplitController div.controls {
    background: #F1F4F8;
    border-radius: 60px;
}

div.Monitor div.decisionsSplitController div.controls button {
    margin: 2px;
    border-radius: 60px;
    width: calc(50% - 4px);
    background: none;
    color: #000;
    border: none;
}
div.Monitor div.decisionsSplitController div.controls button.active {
    color: #2a4e89;
    background: #D7E7FF;
}

div.Monitor div.filterDropdown {
    float: right;
}

div.Monitor div.filterDropdown button {
    color: #000;
    background: none;
    border: none;
    margin: 0;
    padding: 10px 8px;
    font-size: 12px;
}

div.Monitor div.filterDropdown.show button {
    border: none !important;
}

div.Monitor div.footer {
    background: none;
}