@import "_config.scss";
@import '_bootstrap-extensions.scss';

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../fonts/Inter-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('../fonts/SourceSerifPro-Regular.otf');
    font-weight: normal;
    font-style: normal;
}


body {
  margin: 0px;
  padding: 0;
  font-family: "Inter", -apple-system, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1, h2, h3, h4, h5, h6 {
    font-family: "Inter-Medium", -apple-system, 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
    background: #282a2b;
    color: #8c8989;
    padding: 16px 0;
    font-size: $fontSizeL;
}

.footer a {
    color: #3995C3
}

.footer a:hover {
    color: #2DB9FF
}

span.semiTransparent {
    color: $semiTransparentTextColor;
}

a.orange:visited,
.orange {
    color: #ff5a1a;
}

a.orange:hover {
    color: #de4e17;
}

a.button {
    text-decoration: none;
}

button.profileMenuBtn {
    font-size: $fontSizeL;
    font-weight: 500;
        
}


div div button.filtersSubmitBtn,
div div button.stdBtn,
a.button,
button {
    display: inline-block;
    background: none;
    border: none;
    font-size: $fontSizeL;
    border-radius: $stdBorderRadius;
    padding: 8px 15px;
    margin: 6px;
    font-weight: 500;
    cursor: pointer;
    outline: none;

    color: $mainBtnColor;
    background: $mainBtnBg;
    border: $mainBtnBorder;
}

div.PlatformSelector div button.stdBtn,
div.PlatformSelector a.button,
div.PlatformSelector button {
    color: #fff;
    background: #ff5a1a;
    border: 1px solid #ff5a1a;
}

div div button.filtersSubmitBtn:hover,
div div button.stdBtn:hover,
a.button:hover,
button:hover {
    color: $mainBtnHoverColor;
    background: $mainBtnHoverBg;
    border: $mainBtnHoverBorder;
}

div div button.stdBtn.disabled,
a.button.disabled,
button.disabled {
    color: $mainBtnDisabledColor;
    background: $mainBtnDisabledBg;
    border: $mainBtnDisabledBorder;
    -webkit-box-shadow: none; 
    box-shadow: none;
}


div div a.transparentBtn.disabled,
div div button.transparentBtn.disabled {
    color: $transparentBtnDisabledColor;
    background: $transparentBtnDisabledBg;
    border: $transparentBtnDisabledBorder;
    -webkit-box-shadow: none; 
    box-shadow: none;
}

div div a.transparentBtn,
div div button.transparentBtn {
    background: $transparentBtnBG;
    border: $transparentBtnBorder;
    color: $transparentBtnColor;
}

div div a.transparentBtn:hover,
div div button.transparentBtn:hover {
    color: $transparentBtnHoverColor;
    background: $transparentBtnHoverBG;
    border: $transparentBtnHoverBorder;
    -webkit-box-shadow: none; 
    box-shadow: none;
}





a.button.lowPadding,
button.lowPadding {
    padding: 4px 8px;
}

a.button.lightBtn,
button.lightBtn {
    background: $lightBtnBG;
    color: $lightBtnColor;
    border: $lightBtnBorder;
}

a.button.lightBtn:hover,
button.lightBtn:hover {
    background: $lightBtnHoverBG;
    color: $lightBtnHoverColor;
    border: $lightBtnHoverBorder;
}


button.textLikeBtn {
    color: $blueLinkColor;
    background: none;
    border: none;
}

button.textLikeBtn:hover {
    -webkit-box-shadow: none; 
    box-shadow: none;
}

a.button.tabBtn h3,
button.tabBtn h3
{
    margin-bottom: 0;
}

a.button.tabBtn h3,
button.tabBtn h3,
a.button.tabBtn,
button.tabBtn {
    background: $tabBtnBG;
    color: $tabBtnColor;
    border: $tabBtnBorder;
    font-weight: 400;
}

a.button.tabBtn:hover,
button.tabBtn:hover {
    background: $tabBtnHoverBG;
    color: $tabBtnHoverColor;
    border: $tabBtnHoverBorder;
}

a.button svg,
button svg,
a.button img,
button img {
    margin-top: -2px;
    width: 20px;
    margin-right: 5px;
}

div.nheader button.outerCloseBtn,
button.outerCloseBtn {
    background: $outerBtnBG;
    color: $outerBtnColor;
    border: none;

    position: absolute;
    margin-left: -100px;
    margin-top: 0;
    display: none;
}

button.outerCloseBtn:hover {
    background: $outerBtnHoverBG;
    color: $outerBtnHoverColor;
    border: none;

}

body {
  background: $mainBackground;
  overflow: hidden;
  font-size: $fontSizeM;
}


.hidden {
    display: none;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.alignLeft {
    text-align: left;
}

.weightRegular {
    font-weight: 400;
}


.optionsMenu {
    display: none;
    position: absolute;
    background: $optionsMenuBg;
    border-radius: $stdBorderRadius;
    border: 1px solid $panelBorderColor;
    margin-left: 6px;
    z-index: 1000;
    min-width: 125px;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
}

.optionsMenu.doubleSize {
    min-width: 280px;
}

.optionsMenu.padded {
    padding: 20px;
}

.optionsMenu ul {
    margin: 12px 20px;
    padding-left: 0;
}

.optionsMenu.show {
    display: block;
}

.optionsMenu svg {
    width: 18px;
}

div.BrowserApp div.subheader div.sortByMenu div.optionsMenu button,
div.filtersPabel .optionsMenu button,
div.subheader .optionsMenu button,
.optionsMenu a.button,
.optionsMenu button,
div div button.realTransparent
 {
    display: block;
    font-weight: 400;
    color: $linkLikeBtnColor;
    background: $optionsMenuBg;
    border: none;
    margin: 0;
    border-radius: 0;
    width: 100%;
    text-align: left;
    
}

div.BrowserApp div.subheader div.sortByMenu div.optionsMenu button.active,
div.filtersPabel .optionsMenu button.active,
div.subheader .optionsMenu button.active,
.optionsMenu button.active,
div div button.realTransparent.active {
    font-weight: 500;
    color: $transparentBtnActiveColor;
    background: $transparentBtnActiveBG;
    border: none;
}

div div button.realTransparent:hover,
div div button.realTransparent.hovered {
    color: $transparentBtnHoverColor;
    background: $transparentBtnHoverBG;
    border: none;
    outline: none;
}

.optionsMenu button:hover,
.optionsMenu button.hovered {
    background: $transparentBtnHoverBG;
    border: none;
    outline: none;
}


.optionsMenu button svg {
    margin-right: 10px;
}


div.nheader button.outerCloseBtn,
button.outerCloseBtn {
    background: $outerBtnBG;
    color: $outerBtnColor;
    border: none;

    position: absolute;
    margin-left: -100px;
    margin-top: 0;
    display: none;
}

button.outerCloseBtn:hover {
    background: $outerBtnHoverBG;
    color: $outerBtnHoverColor;
    border: none;

}

body {
  background: $mainBackground;
  overflow: hidden;
  font-size: $fontSizeM;
}

.withShadow {
    -webkit-box-shadow: $shadowSizes $shadowColor;
    -moz-box-shadow: $shadowSizes  $shadowColor;
    box-shadow: $shadowSizes  $shadowColor;
}


.stdPadded {
    padding-left: $stdMarginLeft;
    padding-right: $stdMarginLeft;
}

div.popupMode .stdPadded {
    padding-left: 20px;
    padding-right: 20px;
}


.stdTopPadded {
    padding-top: $stdMarginTop;
}

.stdMargined {
    margin-left: $stdMarginLeft;
    margin-right: $stdMarginLeft;

}

div.fullSize {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

h2 {
    font-size: $fontSizeXXL;
    font-weight: 700;
    color: $hColor;
}

h3 {
    font-size: $fontSizeXL;
    font-weight: 700;
    color: $hColor;
}

h4 {
    font-size: $fontSizeL;
    font-weight: 500;
    color: $h4Color;
}

h5 {
    font-size: $fontSizeL;
    font-weight: 400;
    color: $h4Color;
}

h6 {
    font-size: $fontSizeL;
    font-weight: 400;
    color: $h4Color;
    padding-left: 20px;
}

a > h3,
h3.clickable,
a > h4,
h4.clickable {
    color: $hLinkColor;
    cursor: pointer;
}

div.BrowserApp h4.projectTitle {
    color: $docCardTitleColor;
    font-size: $fontSizeXL;
    font-weight: 400;
    margin-bottom: 4px;
    margin-top: 8px;
    font-family: "Inter-Medium", -apple-system, 'Roboto', sans-serif;
}

iframe#contentstoprint {
    border: none;
}

#contentstoprint button {
    display: none;
}

div.fields {
    color: $almostTransparentTextColor;
    font-weight: 500;
    
    font-size: $fontSizeS;
    margin-right: 18px;
    margin-bottom: 14px;
}

div.PlatformSelector div.fields span.value {
    display: block;
}

div.fields span.value {
    color: $greyTextColor;
    font-size: $fontSizeM;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
}

div.fields button,
div.fields a.button {
    margin: 0;
    margin-top: -8px;
}


a,
a:visited {
    color: $mainAccentColor;
}

a:hover {
    color: $mainAccentHoverColor;
}
  
div.error {
    padding: 10px;
    background: $redAccentColor;
    color: $roseBackground;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  


textarea,
input {
  padding: 12px 13px;
  border-radius: $stdBorderRadius; 
  border: 1px solid $inputTintColor;
  width: 200px;
  font-size: $fontSizeXL;
  background: $inputBackground;
  color: $h4Color;
}

input.fullWidth {
    width: 100%;
}

textarea::placeholder,
textarea:-ms-input-placeholder,
textarea::-ms-input-placeholder,
input::placeholder,
input:-ms-input-placeholder,
input::-ms-input-placeholder
{
    color: $inputPlaceholderColor;
    opacity: 1;
}

div.ProfileBrowser input[type=checkbox],
input[type=checkbox] {
    width: auto;
    cursor: pointer;
}


div.vspace {
    width: 100%;
    height: 30px;
}

div.popover {
    max-width: 100%;
}

.sb-avatar span span {
    margin-top: 2px;
    display: inline-block;
}

.obligatory {
    color: $redAccentColor;
}

span.bottomArrow {
    color: #9599a7;
    font-size: 10px;
}

div.docCard {
    cursor: pointer;
    padding: 10px;
}

div.docCard div.contents {
    padding: 15px ;
    border: 1px solid #d5deeb; 
    border-radius: 8px;
    padding-bottom: 0;
    background: #fff;
}

div.docCard:hover div.contents {
    background: $docCardHoverBG;
    
}

div.docCard.opened div.contents {
    background: $docCardOpenBG;
}


div.docCard p.description {
    color: $docCardDescription;
    font-size: $fontSizeM;
    margin-bottom: 15px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 250px;
}


div.docCard div.fields span.value {
    font-size: $fontSizeM;
    color: $docCardFieldValueColor;
}

div.docCardsInternalContainer {
    padding: 0;
}

div.cardsContainer {
    background: #595e67;
}

div.cardsContainer div.docCard.sessionSeen h4 {
    opacity: 0.5;
}

div.cardsContainer div.docCard.sessionSeen.opened h4 {
    opacity: 1;
}

div.cardsContainer div.docCardGroupMarker,
div.cardsContainer div.docCardGroupMarker2,
div.cardsContainer div.docCard div.contents {
    -webkit-box-shadow: 0px 4px 4px 0px rgba(36,67,140,0.1);
    -moz-box-shadow: 0px 4px 4px 0px rgba(36,67,140,0.1);
    box-shadow: 0px 4px 4px 0px rgba(36,67,140,0.1);
}

div.cardsContainer div.docCardGroupMarker,
div.cardsContainer div.docCardGroupMarker2 {
    position: absolute;
    bottom: 7px;
    right: 15px;
    left: 15px;
    height: 3px;
    border-radius: 0 0 $stdBorderRadius $stdBorderRadius;
    border: $transparentBtnAlternativeBorder;
    border-top: none;
    opacity: 0.8;
}

div.cardsContainer div.docCardGroupMarker2 {
    left: 20px;
    right: 20px;
    bottom: 4px;
    opacity: 0.6;
}

div.docCard div.relevancy {
    float: left;
}

div.cardsContainer div.docCard div.flags {
    text-align: right;
    
}

div.cardsContainer div.docCard div.flags span.annItem {
    margin-left: 10px;
    font-size: $fontSizeL;
}


div.cardsContainer div.docCard svg.listItemSavedIcon,
div.cardsContainer div.docCard svg.listItemReadIcon {
    width: 24px;
    height: 24px;
    margin-top: -4px;
    margin-left: -3px;
}

div.cardsContainer div.docCard span.flags svg {
    margin-right: 3px;
}

div.cardsContainer div.docCard div.relevancy span.scoreIndicator {
  display: inline-block;
  width: 4px;
  height: 12px;
  background: #E4E4E4;
  margin-right: 3px;
  margin-top: 4px;
  border-radius: 1px;
}

div.cardsContainer div.docCard div.relevancy span.scoreIndicator.active {
    background: #FF5C1B;
  }

div.popover {
    border: none;
}
  
div.quickEditPopup {
    border-radius: $stdBorderRadius;
    border: none;
    overflow: hidden;
    background: $popoverBtnBG;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
}
  
div.quickEditPopup button {
    border: none;
    padding: 8px 20px;
    margin: 0;
    background: $popoverBtnBG;
    color: $popoverBtnColor;
    border-radius: 0;
    display: block;
}

div.quickEditPopup button + button {
    border-left: 1px solid $panelBorderColor;
}


div.quickEditPopup button:hover {
    background: $popoverBtnHoverBG;
    color: $popoverBtnHoverColor;
}

span.kbHint {
    display: block;
    font-size: $fontSizeS;
    opacity: 0.3;
}

.radiocontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .radiocontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .radiocontainer .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  .radiocontainer:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .radiocontainer input:checked ~ .checkmark {
    background-color: $checkboxColor;
  }
  
  .radiocontainer .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .radiocontainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  .radiocontainer .checkmark:after {
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }


.cbcontainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $linkLikeBtnColor;
    font-size: $fontSizeL;
  }
  
  /* Hide the browser's default checkbox */
  .cbcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .cbcontainer:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .cbcontainer input:checked ~ .checkmark {
    background-color: $checkboxColor;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .cbcontainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .cbcontainer .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  svg.flipH {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }


  
div.ScrollbarsCustom-Wrapper {
    right: 9px !important;
    
}

div.ScrollbarsCustom-Wrapper div.ScrollbarsCustom-Scroller {
    margin-right: -1px;
}

a.button.noText svg,
button.noText svg {
    margin: 0;
}

div.react-joyride__tooltip div
{
    text-align: left !important;
    font-size: $fontSizeM !important;
}

div.react-joyride__tooltip h4 {
    color: #fff;
}

div.react-joyride__tooltip > button {
    display: none;
}

div.react-joyride__tooltip button {
    background-color: #00000020;
    outline: none;
}


div.withFloatingLabel {
    position: relative;
}

div.withFloatingLabel textarea,
div.withFloatingLabel input {
    padding-top: 18px;
    padding-bottom: 6px ;

}


.floating-label {
  position: absolute;
  pointer-events: none;
  left: 14px;
  top: 13px;
  transition: 0.2s ease all;
  color: #b7c0cd;
  font-size: $fontSizeXL;
}

div.field span.passwordStrength > span {
    width: 25%;
    padding-left: 3px;
    padding-right: 3px;
    display: inline-block;
    
}

div.field span.passwordStrength > span > span {
    display: inline-block;
    height: 3px;
    background: #b7c0cd;
    width: 100%;
}

div.field span.passwordStrength.ps1 > span.p1 > span,
div.field span.passwordStrength.ps2 > span.p1 > span,
div.field span.passwordStrength.ps2 > span.p2 > span,
div.field span.passwordStrength.ps3 > span.p1 > span,
div.field span.passwordStrength.ps3 > span.p2 > span,
div.field span.passwordStrength.ps3 > span.p3 > span,
div.field span.passwordStrength.ps4 > span.p1 > span,
div.field span.passwordStrength.ps4 > span.p2 > span,
div.field span.passwordStrength.ps4 > span.p3 > span,
div.field span.passwordStrength.ps4 > span.p4 > span {
    background:#87ce44;
}

div.field span.passwordHint {
    font-size: $fontSizeS;
    text-align: right;
    display: block;
}



div div div.withFloatingLabel textarea:focus ~ .floating-label,
div div div.withFloatingLabel textarea:not(:focus):not([value=""]) ~ .floating-label,
div div div.withFloatingLabel input:focus ~ .floating-label,
div div div.withFloatingLabel input:not(:focus):not([value=""]) ~ .floating-label{
  top: 6px;
  left: 14px;
  font-size: $fontSizeS;
}



div#contentstoprint {
    display: none;    
}

@media print {

    body {
        -webkit-print-color-adjust: exact !important;
      }

      
    * {
        visibility: hidden;
    }    

    div#contentstoprint * {
        visibility: visible;
    }

    div#contentstoprint {
        display: block;
        visibility: visible;
        position: absolute;
        top: 0;
        background: #fff;
    }
}


div.tooltipBody h4 {
    color: #fff;
}

div.tooltipBody button.skipBtn {
    font-weight: normal;
    color: #ffffffaa;
}

div.tooltipBody button.skipBtn:hover {
    font-weight: normal;
    color: #ffffff;
}

div.tooltipBody span.counter {
    margin-right: auto;
    line-height: 56px;
    opacity: 0.7;
}

span.qm {
    color: #fff;
    background: #a3b2c1;
    border-radius: 20px;
    display: inline-block;
    padding: 0px 7px;
    max-width: 200px;
}

span.qm span {
    display: none;
    position: absolute;
    background: #fff;
    color: #2e313a;
    padding: 5px;
    border: 1px solid #b7beca;
    border-radius: $stdBorderRadius;
    z-index: 10;
    width: 200px;

}

span.qm:hover span {
    display: block;
}

.flagGreen {
    color: #37833C;
}

.flagYellow {
    color: #FFCB34;
}

.flagRed {
    color: #C8382D;
}

.flagNeutral {
    color: #BCBCBC;
}

button.collapsePlusBtn,
button.collapsePlusBtn:hover {
    position: absolute;
    left: 6px;
    width: 14px;
    height: 14px;
    line-height: 12px;
    padding: 0 3px !important;    
    margin-top: 7px;
}

div.contactUs {
    background: linear-gradient(0.25turn, #13496B, #258DC8);
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

div.contactUs h3,
div.contactUs a.mailBtn {
    color: #fff;
    font-size: $fontSizeXXL;
}

div.contactUs a.demoBtn {
    margin-left: 20px;
    color: #1B6A98;
    background: #fff;
    border: none;
}

div.contactUs a.demoBtn:hover {
    text-decoration: underline;
}

@import "v2.scss";
