@import "_config.scss";

div.onboarding {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $fadedBackground;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 20000;

}

div.onboarding div.slides {
    position: absolute;
    top: 10%;
    bottom: 10%;
    width: 600px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

div.onboarding div.slide {
    border-radius: $stdBorderRadius;
    background: $docCardHoverBG;
    border-top: 11px solid $borderAccentColor;
    color: $h4Color;
}

div.onboarding div.slide p {
    min-height: 100px;
}


div.onboarding div.controlButtons button {
    min-width: 150px;
}

div.onboarding div.contents {
    padding: 40px 50px 20px 50px;
    
}

div.onboarding h3 {
    font-size: $fontSizeXXXL;
}

div.onboarding button.skipButton {
    opacity: 0.7;
    padding: 5px 12px;
    background: none;
    color: #aaa;
    font-size: 18px;
    margin-right: 0;
    outline: none;
    border: none;
    position: absolute;
    right: 0px;
    margin-top: 0;
}

div.onboarding div.counters {
    text-align: center;
    margin-top: 10px;;
}

div.onboarding div.counters span.counter {
    display: inline-block;
    width: 8px;
    height: 8px;
    opacity: 0.51;
    border-radius: 5px;
    background-color: #ffffff;
    margin-left: 6px;
    margin-right: 6px;
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
    
}

div.onboarding div.counters span.counter.active {
    width: 30px;
    opacity: 1;
}

div.onboarding span.tourHint {
    font-size: $fontSizeS;
}
