@import "_config.scss";

div.ProfileBrowser {
    position: absolute;
    top: 58px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $panelBG;
}

div.ProfileBrowser div.subheader {
    background: $panelBG;
    padding-top: 22px;
    padding-bottom: 22px;
    color: $semiTransparentTextColor;
}

div.ProfileBrowser div.subheader span.id {
    display: inline-block;
    margin-left: 15px;
    vertical-align: middle;
}

div.ProfileBrowser div.subheader h3 {
    color: $h4Color;
    margin-bottom: 0px;
}

div.ProfileBrowser div.fullSize {
    top: 58px;
}

div.ProfileBrowser div.menu button {
    color: $h4Color;
    display: block;
    margin-bottom: 15px;
    font-size: $fontSizeXL;
    text-align: left;
    padding-left: 0;
}

div.ProfileBrowser div.menu button:hover {
    text-decoration: underline;
}

div.ProfileBrowser div.menu a {
    color: $h4Color;
    display: block;
    margin-bottom: 15px;
    font-size: $fontSizeXL;
}

div.ProfileBrowser div.menu a.active {
    font-weight: 600;
}

div.ProfileBrowser div.pstrength{
    width: 200px;
}

div.ProfileBrowser div.orders div.row {
    border-bottom: 2px solid #ededed;
    padding-top: 16px;
    padding-bottom: 0px;
    min-height: 56px;

}

div.ProfileBrowser div.orders div.row.header {
    font-weight: bold;
}


div.ProfileBrowser div.orders div.row a.button,
div.ProfileBrowser div.orders div.row button {
    margin-top: -6px;
    margin-bottom: 10px;
}

div.ProfileBrowser div.orders div.row div {
    vertical-align: middle;
}

div.ProfileBrowser input,
div.ProfileBrowser textarea {
    width: 300px;
}

div.ProfileBrowser div.field.VAT {
    display: inline-block;
}

div.ProfileBrowser div.field.VAT input {
    width: 80px;
}

span.floating-label {
    font-size: 14px;
    padding-top: 0px;
    padding-left: 2px;
    top: 5px;
}

div.ProfileBrowser div.field.VATnumber {
    margin-left: 10px;
    display: inline-block;
}

div.ProfileBrowser div.field.VATnumber input {
    width: 210px;
}