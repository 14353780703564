@import "_config.scss";

div div.ProjectProperties.fullSize {
    background: $fadedBackground;
    z-index: 40000;
    top: 0;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

div.ProjectProperties div.contents {
    background: $panelBackground;
    width: 500px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 140px;
    max-height: 85%;
    border-radius: $stdBorderRadius;
    height: 480px;
    overflow: show;
    
}

div.ProjectProperties span.captionSpan {
    margin-bottom: 15px;
    border-bottom: 1px solid $hSeparatorColor;
    display: block;
}

div.ProjectProperties h3.caption {
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-block;
    border-bottom: $transparentBtnActiveThickBorder;
    margin-bottom: 0;
    margin-left: 21px;
    margin-right: 20px;
}

div.ProjectProperties h4 {
    padding: 20px;
    margin-bottom: 0;
}

div.ProjectProperties div.inputContainer {
    padding-left: 20px;
    padding-right: 20px;
}

div.ProjectProperties input,
div.ProjectProperties textarea {
    width: 100%;
    z-index: 55000;
}

div.ProjectProperties textarea {
    height: 130px;
    resize: none;
}

div.ProjectProperties div.submitContainer {
    border-top: 1px solid $hSeparatorColor;
    margin-top: 15px;
    padding: 20px;
}

div.ProjectProperties div.submitContainer button {
    margin: 0;
    width: 100%;
}