@import "_config.scss";
@import "DocViewerMetaTags";


div.docViewer.fullscreen {
    width: 100%;
}

div.docViewer {
    flex: 1;
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    flex-flow: column;
    background: #fff;
}

div.docViewer div.contentsContainer {
    font-family: 'Source Serif Pro', serif;
    flex: 1 1 auto;
}

div.withFilters div.docViewer div.contentsContainer {
    top: 76px;
}

div.docViewer div.contentsContainer > div.row {
    height: 100%;
}

div.docViewer div.contentsContainer > div.row > div.col-md-11 {
    padding-right: 0;
}

div.docViewer div.textContent {
    border-radius: $stdBorderRadius;
    margin-right: 46px;
    padding-left: 60px;
    padding-right: 20px;
    padding-top: 30px;
    margin-bottom: 20px;
    font-size: $fontSizeXL;
    position: relative;
    max-width: 800px;
}

div.docViewer div.textContent.withHighlightButtons {
    padding-right: 20%;
}

div.docViewer div.textContent span.h4 {
    font-size: $fontSizeXL;
    font-weight: 700;
}

div.docViewer .heatMapInText {
    position: absolute;
    left: 30px;
    top: 0;
    width: 3px;
    height: 100%;
}

div.docViewer .heatMapInText .heatmapInTextRect {
    position: absolute;
    left: 0;
    width: 100%;
}

div.docViewer div.highlightControlsContainer {
    position: absolute;
    right: 0px;
    top: 0;
    width: calc(20% - 0px);

}


div.docViewer div.highlightControlsContainer div.highlightControlElement {
    position: absolute;
    right: 0;
    width: 100%;
    padding: 4px;
    border-radius: $stdBorderRadius;
    cursor: pointer;
}

div.docViewer div.highlightControlsContainer div.highlightControlElement:hover {
    background: #f2f2f2;
}

div.docViewer div.highlightControlsContainer div.highlightControlElement svg {
    position: absolute;
}

div.docViewer div.highlightControlsContainer div.highlightControlElement > span {
    margin-left: 25px;
    font-size: $fontSizeM;
    display: block;
}

div.docViewer div.highlightControlsContainer div.highlightControlElement span.date {
    display: block;
    font-size: $fontSizeXS;
}


div.docViewer div.textContent div.contents {
  margin-top: 30px;
  padding-bottom: 60px;
}

div.docViewer div.heatmap {
  width: 20px;
  height: 100%;
  position: absolute;
  right: 17px;
  top: 0;
}

div.savedDocsViewer + div.docViewer div.heatmapInTextRect,
div.savedDocsViewer + div.docViewer g.heatmap,
div.flatIndexPage div.docViewer g.heatmap {
    display: none;
}

div.popover {
  z-index: 35000;
}


div.docViewer > div.ScrollbarsCustom {
  width: 100%;
  position: absolute !important;
  top: 45px;
  bottom: 0;
  background: #fff;
}

div.docViewer div.contentsContainer .trackY,
div.docViewer div.contentsContainer .ScrollbarsCustom-TrackY {
  position: absolute; 
  border-radius: 4px; 
  user-select: none; 
  width: 10px; 
  height: calc(100% - 40px); 
  top: 20px; 
  right: 10px;
  display: block !important;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

div.docViewer div.contentsContainer .ScrollbarsCustom-ThumbY {
    margin-left: 3px;
}


div.docViewer div.contentsContainer .eScrollContainer {
    position: absolute; 
    border-radius: 4px; 
    user-select: none; 
    width: 20px; 
    height: calc(100% - 40px); 
    top: 20px; 
    right: 10px;
    display: block !important;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: show;
}

div.docViewer svg rect.highlightScrollMarker {
    fill: #ffda00;
    cursor: pointer;
}

div.docViewer rect.heatmapInterval {
    cursor: pointer;
}


div.docViewer svg rect.mustIncludeScrollMarker {
    fill: #FF8080;
    cursor: pointer;
}



div.docViewer div.contentsContainer .eScrollContainer .eScroll {
    width: 20px;
    height: 100%;
    margin-left: -10px;
}

div.docViewer div.contentsContainer .eScrollContainer .eScroll .highlightMarkers line {
    stroke: rgb(255, 242, 194);;
    stroke-width: 4px;
}
div.docViewer div.contentsContainer .eScrollContainer .eScroll .mustIncludeMarkers line {
    stroke: #FFEFF1;
    stroke-width: 2px;
}


.ScrollbarsCustom-TrackY {
    background: none !important; 
  }

  .ScrollbarsCustom-ThumbY{
  margin-left: 3px;
  width: 4px !important;
  background: #c4cbd8!important;

}

div.docViewer div.disclaimerBanner {
    background: #ffeeee;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
}

div.docViewer div.subheader div.row > div > div {
    display: inline-block;

}

div.docViewer div.subheader div.row {
    padding-right: 26px;
    margin-right: 0;
}

div.docViewer div.subheader span.docCounter {
    color: $docCounterColor;
    margin: 10px 10px 0 10px;
    display: inline-block;
}

div.docViewer div.subheader span.docCounter > span {
    color: $docCounterTotalColor;
}

div.docViewer h4.projectTitle {
    font-size: $fontSizeXXL;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    display: block;
    background: #fff;
    top:0;
    padding-bottom: 6px;
}

div.docViewer div.idCard {
    top: 0;
    background: #fff;
    padding: 0 60px 10px 60px;
    margin-left: -60px;
    margin-right: -60px;
    padding-bottom: 0;
}

div.docViewer p.description {
    margin-top: 20px;
}

div.docViewer div.idCard div.hSeparator {
    margin-bottom: 0;
}


div.docViewer div.idCard div.fields {
    margin-top: 0px;
    margin-bottom: 0px;
}

div.docViewer div.idCard div.hSeparator {
    margin-top: 15px;
}

div.docViewer div.idCard div.fields button:first-child,
div.docViewer div.idCard div.fields a.button:first-child {
    margin-left: -8px;
}

div.docViewer div.idCard div.fields button,
div.docViewer div.idCard div.fields a.button {
    padding: 4px 8px;
    border: none;
    color: #9d9d9d;
    border-radius: 13px;;
}

div.docViewer div.idCard div.fields button:hover,
div.docViewer div.idCard div.fields a.button:hover {
    color: #4D535E;
    background: #F1F1F4;
}

div.docViewer div.idCard div.fields button svg,
div.docViewer div.idCard div.fields a.button svg{
    margin-left: 5px;
    margin-right: 0;
    width: 12px;
    height: 12px;
    margin-top: 0;

}

div.docViewer div.hSeparator {
    background: $hSeparatorColor;
    height: 1px;
    margin: 20px -60px;
}


div.docViewer #meta-tag-tooltip {
    pointer-events: auto;
    padding: 0;
    border: none;
    opacity: 1;
    background: none;
}

div.docViewer div.versionsContainer {
    position: absolute;
    top: 39px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $fadedBackground;
    z-index: 20;
}

div.docViewer div.versionsContainer div.scrollContainer {
    height: calc(100% - 100px);
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border-radius: $stdBorderRadius;

}

div.docViewer div.versionsContainer div.scrollContainer div.docVersion {
    color: $h4Color;
    cursor: pointer;
    padding: 10px;
    background: #fff;
    border-top: $cellBorder;
}

div.docViewer div.versionsContainer div.scrollContainer div.docVersion.open {
    font-weight: bold;
}

div.docViewer div.versionsContainer div.scrollContainer div.docVersion:first-child {
    border-radius: $stdBorderRadius $stdBorderRadius 0 0;
    border-top: none;
}

div.docViewer div.versionsContainer div.scrollContainer div.docVersion:last-child {
    border-radius:0 0 $stdBorderRadius $stdBorderRadius;
}


div.saveMenu {
    min-width: 400px;
    max-width: 100%;
    border-radius: $stdBorderRadius;
    background: #f9fafb;
    overflow: hidden;
    border: 1px solid #b7beca;
}

div.saveMenu div.row.header {
    background: #686c73;
}

div.saveMenu div.row > div {
    padding: 8px 27px;
    vertical-align: middle;
}

div.saveMenu div.row.header svg {
    vertical-align: top;
    margin-top: 2px;
    margin-right: 10px;
    width: 24px;
}


div.saveMenu div.caption {
    display: inline-block;
    color: $mainAccentColor;
    font-size: $fontSizeS;
}

div.saveMenu div.row.header div.caption h4 {
    color: #fff;
    margin-bottom: 0;
}

div.saveMenu div.row.entry div.caption h4 {
    color: $mainAccentColor;
    margin-bottom: 0;
}

div.saveMenu div.row.entry + div.row.entry {
    border-top: 1px solid #b7beca;
}

div.saveMenu div.row.entry.tmp {
    background: #e5e6e8;
}

div.saveMenu div.row.entry {
    cursor: pointer;
}

button.saveAsBtn svg {
    transform: rotate(90deg);
    width: 14px;
}

div.docViewer div button.saveAsBtn {
    width: 22px;
    margin-left: -3px;
    padding-left: 3px;
    height: 25px;
}

div.docViewer div.closeDocs {
    background: #fff;
    border-radius: $stdBorderRadius;
    margin-right: 46px;
    margin-bottom: 20px;
}

div.docViewer div.closeDocs div.closeDoc {
    cursor: pointer;
    margin-bottom: 6px;
}

div.docViewer div.closeDocs div.closeDoc:hover {
    color: $hLinkColor;
}

div.docViewerGenPanel {
    position: fixed;
    top: 52px;
    bottom: 0;
    width: 41%;
    left: 0;
    background: #fff;
    border: 1px solid #C0C9D6;

}

div.docViewerGenPanel .output {
    margin-top: 20px;
    padding: 6px;
    font-size: 16px;
}

div.docViewerGenPanel .processingBox {
    padding: 6px;
}


@media print {
    div#contentstoprint div.highlightControlsContainer,
    div#contentstoprint div.heatMapInText {
        display: none;
    }

    div#contentstoprint div.heatmap-marker {
        display: none;
    }

    div#contentstoprint {
        font-family: 'Source Serif Pro', serif;
        font-size: $fontSizeXL;
    }
    
    div#contentstoprint span.h4 {
        font-size: $fontSizeXL;
        font-weight: 700;
    }
    
    
    
    div#contentstoprint div.disclaimerBanner {
        background: #ffeeee;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 10px;
    }
    
    
    div#contentstoprint div.idCard h4.projectTitle {
        font-size: $fontSizeXXL;
    }
    
    
    div#contentstoprint div.idCard div.fields {
        margin-top: 18px;
        margin-bottom: 30px;
    }
    
    div#contentstoprint div.idCard div.fields button,
    div#contentstoprint div.idCard div.fields a.button {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    
    div#contentstoprint div.hSeparator {
        background: $hSeparatorColor;
        height: 1px;
        margin: 20px -60px;
    }
    
    div#contentstoprint div.versionsContainer {
        display: none;
    }
    
    
}
