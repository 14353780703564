div.v2 div.nheader div.projectNavigation a,
div.v2 div.nheader div.projectNavigation button {
    color: #000;
    font-weight: 500;
    vertical-align: middle;
    font-size: 16px;
    margin: 4px;
}

div.v2 div.nheader div.projectNavigation a:hover {
    text-decoration: none;
}

div.v2 div.queryForm div.queryContainer {
    background: none;
}

div.v2 div.queryForm {
    background: none;
}

div.v2 div.queryForm.inline div.taContainer textarea {
    max-height: 40px !important;
}

div.v2 div.queryForm.inline div.taContainer  textarea::-webkit-scrollbar {
    display: none;
  }

div.v2 div.queryForm.inline div.taContainer textarea:focus {
    max-height: 400px !important;
}

div.v2 div.queryForm div.taContainer textarea {
    border-radius: 20px;
    border: 1px solid #C3C6CC;
    padding-left: 160px !important;
    padding-right: 60px;
}

div.v2 div.queryForm.inline {
    display: inline-block;
    width: calc(100vw - 580px);
}


div.v2 div.queryForm.full {
    position: absolute;
    width: 800px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: calc(50vh - 100px);
}

div.v2 div.queryForm span.useByDefaultContainer {
    float: left;
    margin-top: 18px;
}

div.v2 div.queryForm span.useByDefaultContainer span.checkmark {
    margin-top: -3px;
}

div.v2 div.queryForm.full div.queryLang {
    padding-top: 1px;
}

div.v2 div.queryForm.full button.addToWatchBtn {
    display: none;
} 

div.v2 div.queryForm.inline div.stdPadded.stdTopPadded {
    padding: 4px 10px;
}

div.v2 div.queryForm div.stdPadded.stdTopPadded button.submitBtn {
    color: #C3C6CC;
    border-radius: 23px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

div.v2 div.queryForm .lastXDays {
    margin-left: 10px;
    margin-right: 10px;
    padding: 12px 8px;
    width: 65px;
}

div.v2 div.queryForm div.stdPadded.stdTopPadded button.submitBtn:hover {
    background: none !important;
}

div.v2 div.queryForm div#queryLang-container > button.showFiltersButton {
    padding: 8px 8px 7px;
    background: #4A74DF;
    border-radius: 23px;
    margin-left: 2px;
    color: #fff;
    width: 140px;
}

div.v2 div.queryForm div#queryLang-container > button.showFiltersButton span.qLanguageSpan {
    border-left: 2px solid #3B62C7;
    padding-left: 8px;
    margin-left: 8px;
    text-transform: uppercase;
}

div.v2 div.BrowserApp div.subheader button.gvButton {
    color: #000;
}

div.v2 div.BrowserApp div.subheader button.gvButton span.gvIndicator {
    border-radius: 20px;
    background: #ccc;
    width: 22px;
    height: 10px;
    display: inline-block;
    margin-top: 5px;
    margin-left: 4px;
    text-align: left;
}
div.v2 div.BrowserApp div.subheader button.gvButton span.gvIndicator.active {
    background: #4A74DF;
    text-align: right;
}

div.v2 div.BrowserApp div.subheader button.gvButton span.gvIndicator > span {
    background: #fff;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin: 1px;
}

div.v2 h4.languageSelectionHeader {
    margin-top: 20px;
}

div.v2 button.langButton {
    margin: 6px 8px 0 0;
    border: 1px solid #4A74DF;
    text-transform: uppercase;
    color: #4A74DF;
    padding: 2px 7px;
}

div.v2 button.langButton:hover {
    border: 1px solid #4A74DF;
}

div.v2 button.langButton.active {
    color: #fff;
    background: #4A74DF;
}

div.v2 div.nheader svg.logo {
    margin-left: 0;
}

div.v2 div.saveMenu {
    min-width: 160px !important;
}

div.v2 div.saveMenu div.row.entry {
    padding-right: 0;
}

div.v2 div.filtersPanel div.optionsMenu {
    min-width: 400px
}

div.v2 div.filtersPanel div.optionsMenu li.filterItem {
    margin-bottom: 4px;
}

div.v2 div.filtersPanel div.optionsMenu li.filterItem span.plusBtnContainer {
    padding-top: 4px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
}

div.v2 div.filtersPanel div.optionsMenu li.filterItem span.plusBtnContainer button {
    position: auto;
    left: -12px;
    padding-left: 2px !important;
}

div.v2 div.idCard {
    font-family: "Inter", -apple-system, 'Roboto', sans-serif;
    
}

div.v2 div.idCard a,
div.v2 div.idCard button,
div.v2 div.idCard span.value
{
    font-size: 18px;
}

div.v2 div.nheader.personal button.projectBtn {
    display: none;
}

div.v2 div#cdate-slider-container div.filter,
div.v2 div#date-slider-container div.filter {
    display: inline-block;
    margin-right: 10px;

}

div.v2 div#cdate-slider-container {
    margin-bottom: 15px;
}

div.v2 div.queryForm div.miPanelPop {
    display: none;
}

div.v2 div.queryForm.inline div.miPanelPop.shown,
div.v2 div.queryForm.inline div.miPanelPop:hover {
    display: block;
    background: #EDEFF2;
    border-radius: 20px;
    border: 1px solid #1e86ff;
    margin-top: 10px;
}

div.v2 div.queryForm.inline div.miPanelPop label {
    display: inline-block;
    margin-left: 65px;
    margin-right: 10px;
    color: #989DA8;
}

div.v2 div.queryForm.inline div.miPanelPop div.react-tags-wrapper,
div.v2 div.queryForm.inline div.miPanelPop div.react-tags-wrapper .ReactTags__tagInput {
    display: inline-block;
}

div.v2 div.queryForm.inline div.miPanelPop div.react-tags-wrapper input {
    padding: 2px;
    margin-top: 4px;
}

div.v2 div.queryForm.inline div.miPanelPop button.ReactTags__remove {
    padding: 3px;
    margin: 0;
    margin-left: 3px;
}

div.v2 div.queryForm.inline div.miPanelPop span.ReactTags__tag {
    margin-right: 10px;
    border: 1px solid #989DA8;
    border-radius: 4px;
    padding: 0px 7px 1px;
    vertical-align: top;
    margin-top: 4px;
    display: inline-block;
}